import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "migrating-to-v1"
    }}>{`Migrating to v1`}</h1>
    <p>{`React Bootstrap v1 adds full compatibility with Bootstrap 4. Because bootstrap 4 is a major rewrite of the project there
are significant breaking changes from the pre `}<inlineCode parentName="p">{`v1`}</inlineCode>{` react-bootstrap.`}</p>
    <p><strong parentName="p">{`PLEASE FIRST READ THE UPSTREAM BOOTSTRAP MIGRATION GUIDE`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://getbootstrap.com/docs/4.3/migration/"
        }}>{`https://getbootstrap.com/docs/4.3/migration/`}</a></p>
    </blockquote>
    <p>{`React-Bootstrap `}<em parentName="p">{`only`}</em>{` contains components that are present in vanilla Bootstrap. If functionality
was removed from Bootstrap, then it was also removed from React-Bootstrap. This guide does not
repeat information found in the upstream migration guide. Its goal is to document React-Bootstrap-specific
API changes and additions.`}</p>
    <h2 {...{
      "id": "versioning"
    }}>{`Versioning`}</h2>
    <p>{`We will continue to provide general maintenance for Bootstrap 3 components, because there are many projects that continue to depend on Bootstrap 3 support in
`}<inlineCode parentName="p">{`react-bootstrap`}</inlineCode>{`. `}<inlineCode parentName="p">{`react-bootstrap`}</inlineCode>{` package versions will be as follows:`}</p>
    <ul>
      <li parentName="ul">{`Bootstrap 3 support will continue in react-bootstrap versions < `}<inlineCode parentName="li">{`v1.0.0`}</inlineCode></li>
      <li parentName="ul">{`Bootstrap 4 support will be in react-bootstrap versions >= `}<inlineCode parentName="li">{`v1.0.0`}</inlineCode></li>
    </ul>
    <p>{`We are `}<strong parentName="p">{`not`}</strong>{` committing to keeping breaking changes in lockstep with bootstraps major releases,
there may be a react-bootstrap v2 targeting Bootstrap v4 depending on what's best for the project.`}</p>
    <h2 {...{
      "id": "summary-of-breaking-changes-from-v0320"
    }}>{`Summary of breaking changes from v0.32.0`}</h2>
    <p>{`Below is a `}<em parentName="p">{`rough`}</em>{` account of the breaking API changes as well as the minimal change to migrate`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`bsStyle`}</inlineCode>{` -> `}<inlineCode parentName="li">{`variant`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bsClass`}</inlineCode>{` -> `}<inlineCode parentName="li">{`bsPrefix`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bsRole`}</inlineCode>{` has been removed from all components. Components now communicate
via context to allow intermediate nesting of child components`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`componentClass`}</inlineCode>{` -> `}<inlineCode parentName="li">{`as`}</inlineCode></li>
      <li parentName="ul">{`All utils have been removed from main exports, most were internal already and the rest have been moved to external libraries`}</li>
    </ul>
    <h3 {...{
      "id": "grid"
    }}>{`Grid`}</h3>
    <ul>
      <li parentName="ul">{`renamed to Container`}</li>
      <li parentName="ul">{`removed Clearfix`}</li>
    </ul>
    <h4 {...{
      "id": "col"
    }}>{`Col`}</h4>
    <ul>
      <li parentName="ul">{`removed visibility props`}</li>
      <li parentName="ul">{`consolidated col `}<inlineCode parentName="li">{`span`}</inlineCode>{`, `}<inlineCode parentName="li">{`offset`}</inlineCode>{`, and `}<inlineCode parentName="li">{`order`}</inlineCode>{` into an object value prop per breakpoint.`}</li>
    </ul>
    <h3 {...{
      "id": "navbar"
    }}>{`Navbar`}</h3>
    <ul>
      <li parentName="ul">{`removed `}<inlineCode parentName="li">{`Navbar.Header`}</inlineCode></li>
      <li parentName="ul">{`removed `}<inlineCode parentName="li">{`Navbar.Form`}</inlineCode></li>
      <li parentName="ul">{`removed `}<inlineCode parentName="li">{`fluid`}</inlineCode>{`, use your own `}<inlineCode parentName="li">{`Container`}</inlineCode>{` component in.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`inverse`}</inlineCode>{` removed and replaced with `}<inlineCode parentName="li">{`variant="dark"`}</inlineCode></li>
      <li parentName="ul">{`positioning props have been consolidated into `}<inlineCode parentName="li">{`fixed={top|bottom}`}</inlineCode>{` and `}<inlineCode parentName="li">{`sticky={top|bottom}`}</inlineCode>{`, staticTop has been removed`}</li>
    </ul>
    <h4 {...{
      "id": "navbarheader"
    }}>{`NavbarHeader`}</h4>
    <ul>
      <li parentName="ul">{`removed, not present in v4`}</li>
    </ul>
    <h4 {...{
      "id": "navbartoggle"
    }}>{`NavbarToggle`}</h4>
    <ul>
      <li parentName="ul">{`name changed to `}<inlineCode parentName="li">{`Navbar.Toggle`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "navbarbrand"
    }}>{`NavbarBrand`}</h4>
    <ul>
      <li parentName="ul">{`Renders a `}<inlineCode parentName="li">{`<a>`}</inlineCode>{` when an `}<inlineCode parentName="li">{`href`}</inlineCode>{` is provided`}</li>
      <li parentName="ul">{`The presence of `}<inlineCode parentName="li">{`children`}</inlineCode>{` does not skip the wrapping `}<inlineCode parentName="li">{`span`}</inlineCode>{`, use `}<inlineCode parentName="li">{`as`}</inlineCode>{` along with `}<inlineCode parentName="li">{`children`}</inlineCode>{` for custom rendering`}</li>
    </ul>
    <h3 {...{
      "id": "nav"
    }}>{`Nav`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`activeHref`}</inlineCode>{` is removed (only activeKey now)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`bsStyle`}</inlineCode>{` renamed to `}<inlineCode parentName="li">{`variant`}</inlineCode></li>
      <li parentName="ul">{`NavLink hrefs will be used as `}<inlineCode parentName="li">{`eventKey`}</inlineCode>{`s when `}<inlineCode parentName="li">{`eventKey`}</inlineCode>{` is absent`}</li>
      <li parentName="ul">{`Local `}<inlineCode parentName="li">{`onSelect`}</inlineCode>{` handlers are ignored when in the context of a TabContainer or Navbar (MAYBE ADD BACK?)`}</li>
    </ul>
    <h4 {...{
      "id": "navitem"
    }}>{`Nav.Item`}</h4>
    <ul>
      <li parentName="ul">{`Renders `}<em parentName="li">{`only`}</em>{` the outer "item" element, use inconjunction with the new `}<inlineCode parentName="li">{`NavLink`}</inlineCode>{` component`}</li>
      <li parentName="ul">{`default element changed to `}<inlineCode parentName="li">{`<div>`}</inlineCode>{` from a `}<inlineCode parentName="li">{`<li>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`active`}</inlineCode>{` prop removed and moved to `}<inlineCode parentName="li">{`NavLink`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "inputgroup"
    }}>{`InputGroup`}</h3>
    <ul>
      <li parentName="ul">{`removed InputGroup.Button, and InputGroup.Addon`}</li>
      <li parentName="ul">{`added InputGroup.Prepend, InputGroup.Append, InputGroup.Text, InputGroup.Checkbox, InputGroup.Radio`}</li>
    </ul>
    <h3 {...{
      "id": "badge--label"
    }}>{`Badge & Label`}</h3>
    <ul>
      <li parentName="ul">{`removed `}<inlineCode parentName="li">{`Label`}</inlineCode>{`, the `}<inlineCode parentName="li">{`Badge`}</inlineCode>{` component covers both`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`bsStyle`}</inlineCode>{` renamed to `}<inlineCode parentName="li">{`variant`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "panel"
    }}>{`Panel`}</h3>
    <ul>
      <li parentName="ul">{`removed, replaced with Card components`}</li>
    </ul>
    <h3 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h3>
    <ul>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` prop on Dropdown, pass it directly to Dropdown.Toggle`}</li>
      <li parentName="ul">{`Removed bsRole, use function children to render custom Toggles or Menus`}</li>
      <li parentName="ul">{`Removed SplitButton.toggle (replaced with a `}<inlineCode parentName="li">{`split`}</inlineCode>{` prop on the basic Toggle)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`noCaret`}</inlineCode>{` is removed because it's not optional with the styles anymore`}</li>
      <li parentName="ul">{`bsPrefixes are not passed from the parent Dropdown anymore`}</li>
      <li parentName="ul">{`onSelect behavior is now passed to Menu and Toggle via the Context api`}</li>
      <li parentName="ul">{`DropdownMenu is not rendered until opened`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`divider`}</inlineCode>{` has been split out into `}<inlineCode parentName="li">{`Dropdown.Divider`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`header`}</inlineCode>{` has been split out into `}<inlineCode parentName="li">{`Dropdown.Header`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "dropdownbutton"
    }}>{`DropdownButton`}</h4>
    <ul>
      <li parentName="ul">{`Extra props are passed to the underlying Dropdown component, not the Toggle.`}</li>
    </ul>
    <h4 {...{
      "id": "splitbutton"
    }}>{`SplitButton`}</h4>
    <ul>
      <li parentName="ul">{`Extra props are passed to the underlying Dropdown component, not the Toggle.`}</li>
    </ul>
    <h3 {...{
      "id": "navbutton"
    }}>{`NavButton`}</h3>
    <ul>
      <li parentName="ul">{`Extra props are passed to the underlying Dropdown component, not the Toggle.`}</li>
    </ul>
    <h4 {...{
      "id": "menuitem"
    }}>{`MenuItem`}</h4>
    <ul>
      <li parentName="ul">{`renamed to `}<inlineCode parentName="li">{`DropdownItem`}</inlineCode>{` (also exported on `}<inlineCode parentName="li">{`Dropdown`}</inlineCode>{` as `}<inlineCode parentName="li">{`Dropdown.Item`}</inlineCode>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "alert"
    }}>{`Alert`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`onDismiss`}</inlineCode>{` renamed to `}<inlineCode parentName="li">{`onClose`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "well"
    }}>{`Well`}</h3>
    <ul>
      <li parentName="ul">{`removed.`}</li>
    </ul>
    <h3 {...{
      "id": "pager"
    }}>{`Pager`}</h3>
    <ul>
      <li parentName="ul">{`removed.`}</li>
    </ul>
    <h3 {...{
      "id": "controllabel"
    }}>{`ControlLabel`}</h3>
    <ul>
      <li parentName="ul">{`renamed to `}<inlineCode parentName="li">{`FormLabel`}</inlineCode>{` (also exported on `}<inlineCode parentName="li">{`Form`}</inlineCode>{` as `}<inlineCode parentName="li">{`Form.Label`}</inlineCode>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "checkbox-and-radio"
    }}>{`Checkbox and Radio`}</h3>
    <ul>
      <li parentName="ul">{`Consolidated into a single component. Component's name is `}<inlineCode parentName="li">{`FormCheck`}</inlineCode>{` (also exported on `}<inlineCode parentName="li">{`Form`}</inlineCode>{` as `}<inlineCode parentName="li">{`Form.Check`}</inlineCode>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "glyphicon"
    }}>{`Glyphicon`}</h3>
    <ul>
      <li parentName="ul">{`Removed -- icons are not included in Bootstrap 4. Icon support can be provided via react-icons, fontawesome, or a similar external library.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      